jQuery(function ($) {
  $('.page-node .container-min .node img').each(
    function () {
      if($(this).parent().is("p")){
        $(this).unwrap();
      }
      $(this).wrap('<div class="content-img"></div>');
    }
  );

  // add-open-class
  $('.burger-menu').click(function(){
    if($(this).parent().is('.menu-burger--opened')){
      $(this).parent().removeClass('menu-burger--opened');
      $('body').removeClass('menu-open-wrapper-page');
    }else{
      $(this).parent().addClass('menu-burger--opened');
      $('body').addClass('menu-open-wrapper-page');
    }
  });

  // add-open-class
  $('.menu-item__link--dd-open').click(function(){
    if($(this).parent().is('.menu-item--dd-opened')){
      $(this).parent().removeClass('menu-item--dd-opened');
    }else{
      $(this).parent().addClass('menu-item--dd-opened');
    }
  });


// Scrool menu fixed
  $(window).scroll(function() {
    if ($(this).scrollTop() > $('.header').height()){
      $('#wrapper').addClass("sticky");
    }
    else{
      $('#wrapper').removeClass("sticky");
    }
  });
});
